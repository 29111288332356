<template>
  <v-icon
    v-if="correct === true"
    x-small="x-small"
    color="green"
  >
    mdi-circle-outline
  </v-icon>
  <v-icon
    v-else-if="correct === false"
    x-small="x-small"
    color="red"
  >
    mdi-close
  </v-icon>
  <v-icon
    v-else
    x-small="x-small"
    color="grey"
  >
    mdi-minus
  </v-icon>
</template>

<script>
export default {
  name: "CorrectHistoryIcon",
  props: {
    correct: {
      type: Boolean,
      required: false
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <v-dialog
      v-model="show"
      width="360"
    >
      <v-card class="pa-2">
        <template v-if="question">
          <v-card-title class="subtitle-1">
            {{ subtitleText }}
          </v-card-title>
          <v-row class="ma-auto px-4 pb-1">
            <v-chip
              small="small"
              outlined="outlined"
            >
              {{ question.level0 }}
            </v-chip>
            <span class="font-weight-thin">/</span>
            <v-chip
              small="small"
              outlined="outlined"
            >
              重要度{{ question.importance }}
            </v-chip>
            <span class="font-weight-thin">/</span>
            <v-chip
              small="small"
              outlined="outlined"
            >
              {{ firstCorrectRateText }}
            </v-chip>
          </v-row>
          <v-row class="ma-auto px-4 pb-3">
            <v-chip
              small
              outlined
            >
              {{ question.ronbun_flg ? "論文式" : "短答式" }}
            </v-chip>
          </v-row>
          <!-- eslint-disable vue/no-v-text-v-html-on-component vue/no-v-html -->

          <v-card-text
            class="font-weight-black pb-4"
            v-html="question.statement"
          />
          <!-- eslint-enable vue/no-v-text-v-html-on-component vue/no-v-html -->
          <div
            v-if="showImage"
            class="mt-3 pa-3"
          >
            <v-img
              class="mx-auto"
              :src="question.statement_image_url"
              contain="contain"
              max-width="400"
            />
          </div>
          <div class="px-4">
            <v-divider />
          </div>
          <v-card-title class="justify-center">
            {{ answerText }}
          </v-card-title>
          <!-- eslint-disable vue/no-v-text-v-html-on-component vue/no-v-html -->

          <v-card-text
            class="font-weight-black pb-4"
            v-html="question.description"
          />
          <!-- eslint-enable vue/no-v-text-v-html-on-component vue/no-v-html -->
          <v-divider />
          <div class="pa-4">
            <v-textarea
              v-model="question.memo"
              class="memo-textarea"
              label="メモ"
              outlined="outlined"
              auto-grow="auto-grow"
              dense="dense"
              hide-details="hide-details"
              row-height="1"
              @blur="updateMemo"
            />
          </div>
          <div class="px-4 pb-2">
            <v-btn
              color="primary"
              small="small"
              outlined="outlined"
              block="block"
              @click="show = false"
            >
              閉じる
            </v-btn>
          </div>
        </template>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="showSuccessSnackbar"
      color="success"
    >
      メモを保存しました
      <template v-slot:action="{ attrs }">
        <v-icon
          v-bind="attrs"
          dark="dark"
          @click="showSuccessSnackbar = false"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "QuestionStatement",
  props: {
    question: {
      type: Object,
      default: undefined
    },
    open: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      show: false,
      originalMemo: "",
      showSuccessSnackbar: false,
    };
  },
  computed: {
    showImage() {
      if (this.question === null) return false;

      return this.question.statement_image_url && this.question.statement_image_url.startsWith('http')
    },
    subtitleText() {
      return `${this.$cpaMode ? this.question.level1 : this.question.exam} 問${this.question.question_num}`
    },
    firstCorrectRateText() {
      const percentage = (this.question.first_correct_rate * 100).toFixed(0)
      return `全体正答率${percentage}%`
    },
    answerText() {
      if (this.question.ronbun_flg) return '答え'
      return this.question.answer ? '正しい' : '誤り'
    }
  },
  watch: {
    open() {
      this.show = this.open
    },
    show() {
      this.$emit('update:open', this.show)
      this.originalMemo = this.question.memo
    }
  },
  methods: {
    async updateMemo() {
      if (this.originalMemo === this.question.memo) return;

      await axios.post('/api/v1/users/memo', {
        question_id: this.question.id,
        memo: this.question.memo
      }).then(() => {
        this.showSuccessSnackbar = true
        this.$emit('memoFlg', this.question.id, this.question.memo !== '')
      })
    }
  },
}
</script>

<style>
.v-textarea textarea {
  line-height: 1.5 !important;
}

</style>
